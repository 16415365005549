<template>
<div class="nav-header" v-show="isLoggedIn">
    <router-link to="/" class="brand-logo" exact>
        <img class="logo-abbr" :src="logo" alt="" />
        <img class="logo-compact" src="/assets/logo/black-color-text.png" alt="" />
        <img class="brand-title" src="/assets/logo/black-color-text.png" alt="" />
    </router-link>
    <div class="nav-control">
        <div class="hamburger is-active">
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "NavHeader",
    computed: {
        logo() {
            let modules = this.$store.getters.modules.find(v => v.name === this.$route.name);
            if (modules === undefined) {
                return "/assets/logo/icon_defaut.png";
            } else {
                return modules.image
            }
        },
        isLoggedIn() {
            return this.$store.getters.isAuthenticated;
        },
    }
};
</script>
