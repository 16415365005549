import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import router from "../router";
const ls = new SecureLS({ isCompression: true });

// Load Vuex
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";
/**
 * Create Store
 */
export default new Vuex.Store({
    modules: {
        auth,
    },
    strict: debug,
    plugins: [
        createPersistedState({
            key: "initState",
            storage: {
                getItem(key) {
                    try {
                        return ls.get(key)
                    } catch (error) {
                        // localStorage.clear();
                        ls.clear();
                        router.push('/');
                    }
                },
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            },
            reducer(val) {
                if (val.auth.token === null) {
                    return {};
                }
                return val;
            },
        }),
    ],
});