<template>
<div class="authincation h-100">
    <div class="container h-100">
        <div class="row justify-content-center h-100 align-items-center">
            <div class="col-md-6">
                <div class="authincation-content">
                    <div class="row no-gutters">
                        <div class="col-xl-12">
                            <div class="auth-form">
                                <center>
                                    <img src="assets/logo/one-color.png" @click="$router.push('/')" class="mb-4" style="width: 50%" />
                                </center>
                                <h4 class="text-left mb-4">
                                    Sign in your account
                                </h4>
                                <form @submit.prevent="handleSubmit">
                                    <div class="form-group">
                                        <label class="mb-1"><strong>Email</strong></label>
                                        <input type="email" class="form-control" placeholder="Enter your email" v-model="email" />
                                    </div>
                                    <div class="form-group">
                                        <label class="mb-1"><strong>Password</strong></label>
                                        <input type="password" class="form-control" placeholder="Enter your password" v-model="password" />
                                    </div>
                                    <div class="text-center">
                                        <button type="submit" class="btn btn-primary btn-block" :disabled="isSubmit">
                                            {{
                                                    isSubmit
                                                        ? "Loading..."
                                                        : "Sign In"
                                                }}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
/*eslint no-mixed-spaces-and-tabs: 0*/
import axios from 'axios'

export default {
    name: "Login",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Login | %s',
    },
    beforeCreate() {
        if (this.$store.getters.isAuthenticated) {
            this.$router.push("/");
        }
    },
    data() {
        return {
            isSubmit: false,
            email: "",
            password: "",
            ip: "",
        };
    },
    async created() {
        try {
            const resp = await axios.get(
                "https://jsonip.com/", {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            this.ip = resp.data.ip || ''
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        async handleSubmit() {
            try {
                this.isSubmit = true;
                const resp = await axios.post(
                    "user-service/auth/login", {
                        email: this.email,
                        password: this.password,
                        info: {
                            ip: this.ip,
                            agent: window.navigator.userAgent || ''
                        }
                    }, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (resp.data.error == false) {

                    if (this.$route.query.to !== undefined) {
                        await this.$store.dispatch("setUser", {
                            ...resp.data.data,
                            to: this.$route.query.to
                        });
                        return;
                    }
                    await this.$store.dispatch("setUser", resp.data.data);
                    return;
                }
                this.$swal.fire("Error", resp.data.message, "error");
            } catch (error) {

                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");

            } finally {
                this.isSubmit = false;
            }
        },
    },
};
</script>
