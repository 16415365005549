<template>
<div @mouseover="parentStateHoverTrue" @mouseleave="parentStateHoverFalse" class="deznav" v-if="isLoggedIn">
    <div class="deznav-scroll">
        <ul  class="metismenu" id="menu">
            <div v-if="!onApplication">
                <li v-for="(val, i) in modules" :key="'module' + i" :title="val.name">
                    <router-link :to="val.slug_name + '/dashboard'" class="ai-icon" aria-expanded="false" exact>
                        <img :src="val.image" width="40px" height="40px">
                        <span class="nav-text"> {{ val.name }}</span>
                    </router-link>
                </li>
                <li title="Management" v-if="isAdminUserManagement">
                    <router-link to="management/dashboard" class="ai-icon" aria-expanded="false" exact>
                        <img src="/assets/icon/productivity-1.png" width="40px" height="40px">
                        <span class="nav-text">Management</span>
                    </router-link>
                </li>
                <li title="E-commerce" v-if="isAdminUserManagement && isEcommerce">
                    <router-link to="ecommerce/dashboard" class="ai-icon" aria-expanded="false" exact>
                        <img src="/assets/icon/Procurement.png" width="40px" height="40px">
                        <span class="nav-text">E-commerce</span>
                    </router-link>
                </li>
            </div>

            <li :class="{ 'mm-active active-no-child': subIsActive(val.component.name) }" v-for="(val, i) in menu" :key="'child' + i" :title="val.menu.name">
                <router-link :to="val.component.name" class="ai-icon" aria-expanded="false" exact>
                    <!-- <i :class="val.menu.icon"></i> -->
                    <img :src="val.menu.image_icon" height="30px" class="pr-2">
                    <span class="nav-text">{{ val.menu.name }}</span>
                </router-link>
            </li>

            <li v-for="(val, index) in menuAdmin" :key="index">
                <!-- <li class="mm-active" v-for="(val, index) in menuAdmin" :key="index"> -->
                <a class="has-arrow ai-icon" href="javascript:" aria-expanded="false">
                    <!-- <i class="flaticon-381-app"></i> -->
                    <img src="/assets/icon/Task.png" height="30px" class="pr-2">
                    <span class="nav-text">{{ val.parent }}</span>
                </a>
                <ul aria-expanded="false">
                    <li :class="{ 'mm-active': subIsActive(child.component.name) }" v-for="(child, i) in val.child" :key="i">
                        <router-link :to="child.component.name" exact :class="{ 'mm-active': subIsActive(child.component.name) }">
                            {{ child.menu.name }}
                        </router-link>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "Sidebar",
    methods: {
        subIsActive(input) {
            // const paths = Array.isArray(input) ? input : [input];
            // return paths.some(path => {
            return this.$route.path === input ? true : false;
            // return this.$route.path.indexOf(path) === 0; // current path starts with this path string
            // });
        },
        parentStateHoverTrue(){
            this.$parent.changeHoverTrue()
        },
        parentStateHoverFalse(){
            this.$parent.changeHoverFalse()
        }
    },
    computed: {
        ...mapGetters(['menu']),
        ...mapGetters(['menuAdmin']),
        ...mapGetters(['modules']),
        isLoggedIn() {
            return this.$store.getters.isAuthenticated;
        },
        onApplication() {
            return this.$store.getters.isApp;
        },
        isAdminUserManagement() {
            return this.$store.getters.isAdminUserManagement;
        },
        isEcommerce() {
            return this.$store.getters.stateUser.is_ecommerce;
        }
    },
    data() {
        return{
            onHover: false
        }
    }
};
</script>
