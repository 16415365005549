import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueToastr from "vue-toastr";
import VueGoodTablePlugin from "vue-good-table";
import {
    BModal,
    VBModal,
    ModalPlugin,
    BFormDatepicker,
    BCalendar,
    BButton,
    BIcon,
    IconsPlugin,
    BTable,
    BBadge,
    BFormCheckbox,
    BFormFile,
    CardPlugin,
    BButtonGroup,
    BAvatar,
    BAvatarGroup,
    BSkeletonImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import VueSweetalert2 from "vue-sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';
import SimpleVueValidation from "simple-vue-validator";
import VueCurrencyInput from "vue-currency-input";
import VueSignature from "vue-signature-pad";
// import LottiePlayer from "lottie-player-vue";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import VueCharts from 'vue-chartjs';
import VueMeta from "vue-meta";
import VueExcelViewer from '@uublue/vue-excel-viewer'

// import the styles
import 'vue-good-table/dist/vue-good-table.css';
import "vue-select/dist/vue-select.css";

axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
// const token = store.getters.token;
// console.log('one step', token);
// if (token) {
// axios.defaults.headers.common['Authorization'] = store.getters.token;
// }

// axios.interceptors.response.use(undefined, function(error) {
//   if (error) {
//     const originalRequest = error.config;
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       store.dispatch("logOut");
//       return router.push("/login");
//     }
//   }
// });



Vue.use(VueGoodTablePlugin);
Vue.use(VueToastr);
Vue.use(VueSweetalert2);
Vue.use(ModalPlugin);
Vue.use(IconsPlugin);
Vue.use(SimpleVueValidation);
Vue.use(VueCurrencyInput);
Vue.use(VueSignature);
// Vue.use(LottiePlayer);
Vue.use(CardPlugin);
Vue.use(VueCharts);
Vue.use(VueMeta);

const moment = require("moment");
require("moment/locale/id");
Vue.use(require("vue-moment"), {
    moment,
});
Vue.use(VueExcelViewer)

// register the components
Vue.component("BModal", BModal);
Vue.directive("b-modal", VBModal);
Vue.component("b-form-datepicker", BFormDatepicker);
Vue.component("b-calendar", BCalendar);
Vue.component("v-select", vSelect);
Vue.component("b-form-file", BFormFile);
Vue.component("b-button", BButton);
Vue.component("b-icon", BIcon);
Vue.component("b-table", BTable);
Vue.component("b-badge", BBadge);
Vue.component("b-form-checkbox", BFormCheckbox);
Vue.component("b-button-group", BButtonGroup);
Vue.component("b-avatar", BAvatar);
Vue.component("b-avatar-group", BAvatarGroup);
Vue.component("b-skeleton-img", BSkeletonImg);

Sentry.init({
  Vue,
  dsn: process.env.DSN_SENTRY,
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0,
});

Vue.config.productionTip = false;
Vue.prototype.$sentry = Sentry;
Vue.prototype.$mockapi = process.env.VUE_APP_BASEURL_MOCK;

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MM-DD-YYYY')
  }
}),

Vue.filter('formatTime', function(value) {
  if (value) {
    return moment(String(value)).format('hh:mm')
  }
}),

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
