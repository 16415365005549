<template>
<div>
    <Preloader v-show="!onLogin" />
    <div :id="!onLogin ? 'main-wrapper' : ''" :style="[isLoggedIn ? '' : {'margin-top': '6rem'}]" :class="toggleSidebar ? 'show' : 'show menu-toggle'">
        <NavHeader v-show="!onLogin" />
        <Header v-show="!onLogin" />
        <Sidebar v-show="!onLogin"/>
        <div :class="{ 'content-body': !onLogin }" :style="isLoggedIn ? '' : 'padding-top: 5px !important;' ">
            <!-- <div :class="{ 'content-body': isLoggedIn }"> -->
            <router-view></router-view>
        </div>
    </div>
</div>
</template>

<script>
import Preloader from "./components/Preloader.vue";
import NavHeader from "./components/NavHeader.vue";
import Header from "./components/Header.vue";
import Sidebar from "./components/Sidebar.vue";

export default {
    name: "App",
    components: {
        Preloader,
        NavHeader,
        Header,
        Sidebar,
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters.isAuthenticated;
        },
        onLogin() {
            return this.$route.path == '/login' ? true : false;
        },
        toggleSidebar() {
            return this.onHover
        }
    },
    methods: {
        changeHoverTrue(){
            this.onHover = true
        },
        changeHoverFalse(){
            this.onHover = false
        }
    },
    data(){
        return{
            onHover: false
        }
    }
};
</script>
