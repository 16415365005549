<template>
<div class="container-fluid">
    <div class="row">
        <!-- <div style="width:100%;margin:20px auto;height:400px">
            <slider ref="slider" :options="options">
                <slideritem v-for="(item,index) in someList" :key="index" :style="item.style">{{item.html}}</slideritem>
                <div slot="loading">loading...</div>
            </slider>
        </div> -->
    </div>
    <div class="row page-titles mx-0 mt-5">
        <div class="col-sm-6 p-md-0">
            <div class="welcome-text">
                <h4>OUR MODULES</h4>
            </div>
        </div>
    </div>
    <div class="row" v-show="isLoggedIn">
        <div class="col-xl-2 col-lg-6 col-xxl-2 col-sm-6" v-for="(modules, i) in listModule" :key="i">
            <div class="card card-application" v-on:click="chooseModule(modules)">
                <div class="card-body text-center ai-icon">
                    <!-- <i :class="modules.icon" style="font-size:6em"></i> -->
                    <img :src="modules.image">
                    <h3 class="my-2">{{ modules.name }}</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-show="!isLoggedIn">
        <div class="col-xl-2 col-lg-6 col-xxl-2 col-sm-6" v-for="(modules, i) in listModule.slice(0,4)" :key="i">
            <div class="card card-application" v-on:click="chooseModule(modules)">
                <div class="card-body text-center ai-icon">
                    <!-- <i :class="modules.icon" style="font-size:6em"></i> -->
                    <img :src="modules.image">
                    <h3 class="my-2">{{ modules.name }}</h3>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col-lg-9">
            <div class="card">
                <div class="card-header border-0 pb-0 d-sm-flex d-block">
                    <div>
                        <h4 class="card-title mb-1 fs-28 font-w600">News</h4>
                    </div>
                </div>
                <div class="card-body p-0">
                    <div class="media review-box" v-for="object in listActivity.slice(0, 5)" v-bind:key="object.value">
                        <img class="mr-3 img-fluid btn-rounded" width="55" src="/assets/icon/HRM.png" alt="DexignZone">
                        <div class="media-body">
                            <h4 class="mt-0 mb-0 text-black">{{object.title}}</h4>
                            <ul class="review-meta mb-3 d-block d-sm-flex align-items-center">
                                <li class="mr-3"><small>{{object.date | moment("dddd, Do MMMM YYYY")}}</small></li>
                            </ul>
                            <p class="mb-3 text-secondary">{{object.activity}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <!-- <div class="row page-titles mx-0 mb-5 mt-5" v-show="!isLoggedIn">
        <div class="col-sm-6 p-md-0">
            <div class="welcome-text">
                <h4>NEWS</h4>
            </div>
        </div>
    </div>
    <div class="row" v-show="!isLoggedIn">
        <div class="col-md-6 news-section p-0">
            <div class="news-item">
                <div class="news-post">
                    <span class="badge badge-success mb-3 mb-3">
                        <a href="#" rel="tag" style="color:white">Latest</a>
                    </span>
                    <h1 class="news-title text-white">
                        <a>
                            {{latestNews1.title}}
                        </a>
                    </h1>
                    <span class="news-icon">
                        <span><i class="fa fa-calendar" aria-hidden="true"></i> {{latestNews1.pubDate | formatDate}}</span>
                        <span><i class="fa fa-clock-o" aria-hidden="true"></i> {{latestNews1.pubDate | formatTime}} </span>
                    </span>
                </div>
                <img :src="latestNews1.thumbnail" style="width:985.55px;height:615.96px">
            </div>
            <div class="news-item mb-5">
                <div class="news-post">
                    <span class="badge badge-success mb-3 mb-3">
                        <a href="#" rel="tag" style="color:white">Latest</a>
                    </span>
                    <h1 class="news-title text-white">
                        <a>
                            {{latestNews2.title}}
                        </a>
                    </h1>
                    <span class="news-icon">
                        <span><i class="fa fa-calendar" aria-hidden="true"></i> {{latestNews2.pubDate | formatDate}}</span>
                        <span><i class="fa fa-clock-o" aria-hidden="true"></i> {{latestNews2.pubDate | formatTime}} </span>
                    </span>
                </div>
                <img :src="latestNews2.thumbnail" style="width:985.55px;height:615.96px">
            </div>
        </div>
        <div class="col-md-6 news-section p-0">
            <div class="news-item">
                <div class="news-post">
                    <span class="badge badge-primary mb-3">
                        <a href="#" rel="tag" style="color:white">Health</a>
                    </span>
                    <h1 class="news-title text-white">
                        <a>
                            {{healthNews1.title}}
                        </a>
                    </h1>
                    <span class="news-icon">
                        <span><i class="fa fa-calendar" aria-hidden="true"></i> {{healthNews1.pubDate | formatDate}}</span>
                        <span><i class="fa fa-clock-o" aria-hidden="true"></i> {{healthNews1.pubDate | formatTime}} </span>
                    </span>
                </div>
                <img :src="healthNews1.thumbnail" style="width:985.55px;height:615.96px">
            </div>
            <div class="news-item mb-5">
                <div class="news-post">
                    <span class="badge badge-primary mb-3">
                        <a href="#" rel="tag" style="color:white">Health</a>
                    </span>
                    <h1 class="news-title text-white">
                        <a>
                            {{healthNews2.title}}
                        </a>
                    </h1>
                    <span class="news-icon">
                        <span><i class="fa fa-calendar" aria-hidden="true"></i> {{healthNews2.pubDate | formatDate}}</span>
                        <span><i class="fa fa-clock-o" aria-hidden="true"></i> {{healthNews2.pubDate | formatTime}} </span>
                    </span>
                </div>
                <img :src="healthNews2.thumbnail" style="width:985.55px;height:615.96px">
            </div>
        </div>
    </div> -->

    <!-- end row -->
    <!-- <div class="row mt-3" v-show="isLoggedIn">
        <div class="col-xl-4 col-lg-6 col-sm-6">
            <div class="widget-stat card bg-danger">
                <div class="card-body p-4">
                    <div class="media">
                        <span class="mr-3">
                            <img style="width: auto; height: 60px" src="assets/logo/finger.png" alt="" />
                        </span>
                        <div class="media-body text-white text-right">
                            <p class="mb-1">Oops, you haven't check in today</p>
                            <h4 class="text-white">Let's Check In !</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-sm-6">
            <div class="widget-stat card" style="background-color:#08c7fb !important;">
                <div class="card-body p-4">
                    <div class="media">
                        <span class="mr-3" style="background-color: transparent !important">
                            <img class="rounded-circle" style="width: auto; height: 80px;box-shadow:0 0 0 4px #fff;" src="https://storages.solusidental.id/media/2733271612141546-M6dd7f42c2f9bd14ef5887c9af28c06911610067430743.png" alt="" />
                        </span>
                        <div class="media-body text-white text-right">
                            <p class="mb-1">Happy Birthday!</p>
                            <h4 class="text-white">Wish You All the Best!</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="row page-titles mx-0 mt-5" v-show="isLoggedIn">
        <div class="col-sm-6 p-md-0">
            <div class="welcome-text">
                <h4>Weather</h4>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-8">
            <div class="row" v-show="isLoggedIn">
                <div class="col-xl-3 col-xxl-3 col-sm-4" v-for="(val, index) in weather" :key="index">
                    <div class="card overflow-hidden">
                        <div :class="val.cuaca == 'Berawan' ? 'social-graph-wrapper widget-linkedin' : 'social-graph-wrapper widget-googleplus'">
                            <span class="s-icon"><i :class="val.cuaca == 'Berawan' ? 'fa fa-sun-o' : 'fa fa-bolt' "></i></span>
                            <h5 class="text-white">{{ val.tempC }} °C</h5>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="pt-3 pb-3 ps-0 pe-0 text-center">
                                    <h4 class="m-1"><span class="counter">{{ val.cuaca }}</span></h4>
                                    <p class="m-0">{{ val.jamCuaca.substring(11, 16) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="isLoggedIn" class="app mt-3">
                <vue-clock />
            </div>
            <div class="card">
                <div class="card-header border-0 pb-0 d-sm-flex d-block">
                    <div>
                        <h4 class="card-title mb-1 fs-28 font-w600">Upcoming Event</h4>
                    </div>
                </div>
                <div class="card-body p-0 col-12">
                    <div class="media review-box d-flex">
                        <div v-for="(event, index) in event" :key="index" class="media-body">
                            <h4 class="mt-0 mb-0 text-black">{{event.title}}</h4>
                            <ul class="review-meta mb-3 d-block d-sm-flex align-items-center">
                                <!-- <li class="me-3"><small>Head Marketing</small></li> -->
                                <!-- <li class="me-3"><small>24 June 2020</small></li> -->
                                <li class="ms-auto mt-2"><span class="badge badge-rounded badge-warning light font-w500">{{event.start_date}} {{event.end_date}}</span></li>
                            </ul>
                            <p class="mb-3 text-secondary">{{event.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4" v-if="birthday.length != 0">
            <div class="card">
                <img class="card-img-top" src="/assets/images/birthday2.jpg" alt="Card image cap" height="50%">
                <div class="card-body">
                    <h5 class="card-title">{{birthday.length}} Customer have their birthdays today!</h5>
                    <h5 class="card-title mb-3">Wish them all the best by sending a gift card!</h5>
                    <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" :rows="birthday" :columns="columns" compactMode>
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'action'">
                                <b-button-group>
                                    <b-button @click="sendGiftCard(props.row.id)" title="Detail" variant="outline-success" size="sm"><i class="fa fa-gift"></i> Send E-Card </b-button>
                                </b-button-group>
                            </div>
                            <div v-else-if="props.column.field == 'age'">
                                <span class="w-space-no">Age {{ props.row.age }}</span>
                            </div>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </div>
        </div>
    </div>

    <!-- end row -->
    <div hidden class="row page-titles mx-0 mt-5" v-show="isLoggedIn">
        <div class="col-sm-6 p-md-0">
            <div class="welcome-text">
                <h4>NEED YOUR APPROVAL</h4>
            </div>
        </div>
    </div>
    <div hidden class="row" v-show="isLoggedIn">
        <div class="col-xl-4 col-lg-6 col-sm-6">
            <div class="widget-stat card" style="background-color: #615f5f82">
                <div class="card-body p-4">
                    <div class="media">
                        <span class="mr-3" style="background-color: transparent">
                            <img style="width: auto; height: 90px" src="https://corp2.jojonomic.com/vue/application-dashboard/dist/static/img/jojoexpense.05598c3.png" alt="" />
                        </span>
                        <div class="media-body text-white text-right">
                            <p class="mb-1" style="font-size: 28px">REIMBURSEMENT</p>
                            <h4 class="text-white">12 TRANSACTION</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-sm-6">
            <div class="widget-stat card" style="background-color: #615f5f82">
                <div class="card-body p-4">
                    <div class="media">
                        <span class="mr-3" style="background-color: transparent">
                            <img style="width: auto; height: 90px" src="https://corp2.jojonomic.com/vue/application-dashboard/dist/static/img/jojotimes.4bde2b6.png" alt="" />
                        </span>
                        <div class="media-body text-white text-right">
                            <p class="mb-1" style="font-size: 28px">LEAVE</p>
                            <h4 class="text-white">5 REQUEST</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end row -->
    <div hidden class="row" v-show="isLoggedIn">
        <div class="col-xl-4 col-lg-6 col-sm-6">
            <div class="widget-stat card" style="background-color: #615f5f82">
                <div class="card-body p-4">
                    <div class="media">
                        <span class="mr-3" style="background-color: transparent">
                            <img style="width: auto; height: 90px" src="https://corp2.jojonomic.com/vue/application-dashboard/dist/static/img/jojoexpense.05598c3.png" alt="" />
                        </span>
                        <div class="media-body text-white text-right">
                            <p class="mb-1" style="font-size: 28px">CASH ADVANCE</p>
                            <h4 class="text-white">3 REQUEST</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-sm-6">
            <div class="widget-stat card" style="background-color: #615f5f82">
                <div class="card-body p-4">
                    <div class="media">
                        <span class="mr-3" style="background-color: transparent">
                            <img style="width: auto; height: 90px" src="https://corp2.jojonomic.com/vue/application-dashboard/dist/static/img/jojobiztrip.old.b91986f.png" alt="" />
                        </span>
                        <div class="media-body text-white text-right">
                            <p class="mb-1" style="font-size: 28px">DIGITAL DOCUMENT</p>
                            <h4 class="text-white">5 NEW DOCUMENT</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import VueClock from '@dangvanthanh/vue-clock';
// import {
//     slider,
//     slideritem
// } from 'vue-concise-slider'
const moment = require("moment");

export default {
    name: "Home",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Home | %s',
    },
    components: {
        VueClock
        // slider,
        // slideritem
    },
    data() {
        return {
            birthday: [],
            event: [],
            listApplication: [],
            listModule: [],
            listActivity: [],
            latestNews1: [],
            latestNews2: [],
            weather: [],
            healthNews1: {},
            healthNews2: {},
            slide: 0,
            sliding: null,
            columns: [{
                    field: "name",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-center text-nowrap",
                    sortable: false,
                },
                {
                    field: "age",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-center text-nowrap",
                    sortable: false,
                },
                {
                    field: "action",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-center text-nowrap",
                    sortable: false,
                },
            ],
            options: {
                currentPage: 0
            }
        };
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters.isAuthenticated;
        },
        isAppActive() {
            return this.$store.getters.isApp;
        }
    },
    async mounted() {
        this.getModule()
        this.getData()
        // this.getLatestNews()
        // this.getHealthNews()
        this.getWeather()
        this.getBirthday()
        this.getEvent
        await this.$store.dispatch('setAppRole', [])
        await this.$store.dispatch('resetApp');
        await this.$store.dispatch('setLoading', true);
    },
    methods: {
        async chooseModule(e) {
            return e.disabled ? this.$toastr.w("You don't have access this module", "Warning") : this.$router.push('/' + e.slug_name.toLowerCase() + '/dashboard');
        },
        async getEvent() {
            try {
                const resp = await axios.post(
                    "dental-erp-service/events", {
                        limit: 5,
                        page: 1,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.event = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Event", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Event ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async sendGiftCard(customer) {
            try {
                await this.$store.dispatch("setLoading", true);
                const resp = await axios.post(
                    "dental-erp-service/dashboard/customer/create/birthday_card", {
                        id: customer,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error == false) {
                    this.$swal.fire("Success", "Successfully created, redirecting to E-Card", "success").then(
                        function () {
                            window.open(resp.data.url)
                        }
                    )
                    this.getData();
                    return;
                }
                this.$swal.fire("Error", resp.data.message, "error");
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");

            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async getBirthday() {
            try {
                let resp = await axios.get("dental-erp-service/dashboard/customer/birthday_today", {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.birthday = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async getModule() {
            let urlModule = "user-service/module";
            if (this.$store.getters.isAuthenticated) {
                urlModule = "user-service/auth/module";
            }

            try {
                const resp = await axios.get(urlModule, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.listModule = resp.data.data;
                    if (this.$store.getters.hasReportAccess) {
                        this.listModule.push({
                            "id": "1ea16004-6549-47ab-b62e-bc5f4100463b",
                            "name": "Report",
                            "slug_name": "report",
                            "icon": "flaticon-381-networking",
                            "image": "/assets/icon/Human-Resource.png",
                            "disabled": false
                        })
                    }
                    this.$store.dispatch('setModule', this.listModule);
                    return;
                }
                this.$swal.fire("Error", resp.data.message, "error");
            } catch (error) {
                if (error.response !== undefined) {
                    if (error.response.data !== undefined) {
                        if (error.response.data.error_auth !== undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async getData() {
            try {
                let resp = await axios.get(`${this.$mockapi}api/dental-erp-service/dashboard/home`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (resp.data.error === false) {
                    this.listActivity = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async getLatestNews() {
            try {
                let resp = await axios.get(`https://api-berita-indonesia.vercel.app/cnn/terbaru/`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (resp.data.success === true) {
                    this.latestNews1 = resp.data.data.posts[0];
                    this.latestNews2 = resp.data.data.posts[1];
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async getHealthNews() {
            try {
                let resp = await axios.get(`https://api-berita-indonesia.vercel.app/suara/health/`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (resp.data.success === true) {
                    this.healthNews1 = resp.data.data.posts[0];
                    this.healthNews2 = resp.data.data.posts[1];
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }
                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async getWeather() {
            // "https://ibnux.github.io/BMKG-importer/cuaca/5002333.json"
            let resp = await axios.get("https://ibnux.github.io/BMKG-importer/cuaca/5002333.json", {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            if (resp.data.length > 0) {
                this.weather = resp.data.filter((value) => value.jamCuaca.includes(moment().format('YYYY-MM-DD')))
            }
        }
    },
};
</script>

<style>
.card-application:hover {
    box-shadow: 8px 8px 5px rgb(99, 99, 112);
    transform: scale(1.1);
    cursor: pointer;
}

.card-application {
    box-shadow: 3px 3px 3px rgb(205, 205, 212);
    background-color: #F7F7F7;
}

.news-section {
    padding: 0;
}

.news-item {
    float: left;
    opacity: 1;
    overflow: hidden;
    position: relative;
}

/* .news-item:hover .news-item img {
    width:
} */

.news-item img {
    width: 100%;
    height: auto;
}

/* .news-item a,
span {
    color: #FFF;
} */

.news-item .news-post span.news-icon {
    font-family: sans-serif;
    font-size: 14px;
    color: #fff;
    margin-top: 15px;
    display: inline-block;
}

.news-item .news-post span.news-icon span {
    margin-right: 15px;
}

.news-item .news-post span.news-social-icon {
    float: right;
}

.news-item .news-post span.news-social-icon span i {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    padding: 8px;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    text-align: center;
    margin: 2px 4px 0px 2px;
}

.news-item .news-post span.news-social-icon span i:hover {
    background-color: #e1e1e1;
    color: #000;
}

.news-item .news-post {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
    padding: 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.news-item .news-post h1.news-title {
    font-size: 28px;
}

.news-post .badge {
    letter-spacing: 2px;
    font-size: 15px;
}
</style>
