import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import store from "../store";
import NotFoundComponent from '../components/NotFoundComponent';

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/profile",
        name: "Profile",
        component: () => import("../views/Profile.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/warehouse/:component",
        name: "Warehouse",
        component: () => import("../components/Warehouse.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/purchasing/:component",
        name: "Purchasing",
        component: () => import("../components/Purchasing.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/sales/:component",
        name: "Sales",
        component: () => import("../components/Sales.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/finance/:component",
        name: "Finance",
        component: () => import("../components/Finance.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/admin/:component",
        name: "Admin",
        component: () => import("../components/Admin.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/management/:component",
        name: "Management",
        component: () => import("../components/Management.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/report/:component",
        name: "Report",
        component: () => import("../components/Report.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/broadcast/:component",
        name: "Broadcast",
        component: () => import("../components/Broadcast.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/dynamic_form/:component",
        name: "Dynamic Form",
        component: () => import("../components/DynamicForm.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/ecommerce/:component",
        name: "E-commerce",
        component: () => import("../components/Ecommerce.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    { path: "*", component: NotFoundComponent },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    // linkExactActiveClass: "mm-active active-no-child",
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
            next();
            return;
        }
        next("/login");
    } else {
        next();
    }
});

export default router;
