<template>
<div id="preloader" v-if="isLoading">
    <div class="sk-three-bounce">
        <div class="centerpreloader">
            <div class="ui-loading"></div>
            <h5 class="text-white">
                Loading...
            </h5>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'Preloader',
    computed: {
        isLoading() {
            return this.$store.getters.isLoading;
        }
    }
}
</script>

<style>
.centerofcontent {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
}

.ui-intro-pagination button {
    background-color: #41a4ce
}

.ui-intro-pagination .selected {
    color: #41a4ce
}

.ui-intro-pagination {
    border-top: 1px solid #41a4ce
}

@-webkit-keyframes ckw {
    0% {
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg)
    }

    100% {
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg)
    }
}

@-moz-keyframes ckw {
    0% {
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg)
    }

    100% {
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg)
    }
}

@-webkit-keyframes cckw {
    0% {
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg)
    }

    100% {
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg)
    }
}

@-moz-keyframes cckw {
    0% {
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg)
    }

    100% {
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg)
    }
}

.ui-loading {
    background: url(https://storages.solusidental.id/media/1551621611383439-loading-page.png) no-repeat 50% 50%;
    /* background-size: 60px 50px; */
    width: 80px;
    height: 80px;
    -moz-animation-name: cckw;
    animation-duration: 2s;
    -webkit-animation-name: cckw;
    -webkit-animation-duration: 2.84s;
    animation-iteration-count: infinite
}

.centerpreloader {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -40px 0 0 -40px;
}
</style>
