// import axios from 'axios';
import router from '../../router';

const state = {
    token: localStorage.getItem("token") || "",
    user: {},
    loading: false,
    listOfMenu: [],
    application: false,
    applicationRole: [],
    menuDefault: [],
    modules: [],
    currentPage: "",
    abilitiesApproveSO: false,
    abilitiesApprovePO: false
};

let menu = [
    {
        parent: "Master Data",
        child: [
            {
                component: {
                    name: "/admin/warehouse-admin",
                    slug_name: "warehouse_admin",
                },
                menu: {
                    name: "Warehouse",
                    slug_name: "warehouse",
                    icon: "flaticon-381-app",
                },
            },
            {
                component: {
                    name: "/admin/category-admin",
                    slug_name: "category_admin",
                },
                menu: {
                    name: "Category",
                    slug_name: "category",
                    icon: "flaticon-381-app",
                },
            },
            {
                component: {
                    name: "/admin/principle-admin",
                    slug_name: "principle_admin",
                },
                menu: {
                    name: "Principle",
                    slug_name: "principle",
                    icon: "flaticon-381-app",
                },
            },
            {
                component: {
                    name: "/admin/supplier-admin",
                    slug_name: "supplier_admin",
                },
                menu: {
                    name: "Supplier",
                    slug_name: "supplier",
                    icon: "flaticon-381-app",
                },
            },
            {
                component: {
                    name: "/admin/brand-admin",
                    slug_name: "brand_admin",
                },
                menu: {
                    name: "Brand",
                    slug_name: "brand",
                    icon: "flaticon-381-app",
                },
            },
            {
                component: {
                    name: "/admin/product-admin",
                    slug_name: "product_admin",
                },
                menu: {
                    name: "Products",
                    slug_name: "products",
                    icon: "flaticon-381-app",
                },
            },
            {
                component: {
                    name: "/admin/customer-admin",
                    slug_name: "customer_admin",
                },
                menu: {
                    name: "Customers",
                    slug_name: "customers",
                    icon: "flaticon-381-app",
                },
            },
            {
                component: {
                    name: "/admin/payment-admin",
                    slug_name: "payment_admin",
                },
                menu: {
                    name: "Payments",
                    slug_name: "payments",
                    icon: "flaticon-381-app",
                },
            },
            {
                component: {
                    name: "/admin/bank-admin",
                    slug_name: "bank_admin",
                },
                menu: {
                    name: "Bank",
                    slug_name: "bank",
                    icon: "flaticon-381-app",
                },
            },
            {
                component: {
                    name: "/admin/area-admin",
                    slug_name: "area_admin",
                },
                menu: {
                    name: "Area",
                    slug_name: "area",
                    icon: "flaticon-381-app",
                },
            },
            {
                component: {
                    name: "/admin/event-admin",
                    slug_name: "area_admin",
                },
                menu: {
                    name: "Event",
                    slug_name: "event",
                    icon: "flaticon-381-app",
                },
            },
        ],
    },
];

const getters = {
    pageChange: (state) => state.currentPage,
	isAuthenticated: (state) => !!state.token,
	stateUser: (state) => state.user,
	isLoading: (state) => state.loading,
	token: (state) => state.token,
	menu: (state) => {
        if (state.application) {
            return state.listOfMenu;
        }
        return [];
    },
    menuAdmin: (state) => {
        if (state.application) {
            if (state.user.is_admin) {
                return state.menuDefault;
            }
        }
        return [];
    },
    modules: (state) => state.modules,
    isMenuEmpty: (state) => state.listOfMenu.length == 0,
	isApp: (state) => state.application,
    isAdmin: (state) => state.user.is_admin || false,
    hasReportAccess: (state) => state.user.is_report_access || false,
    levelAccess: (state) => state.user.level_access,
    isAdminUserManagement: (state) => state.user.is_admin_user_management || false,
    canApproveSO: (state) => state.abilitiesApproveSO || false,
    canApprovePO: (state) => state.abilitiesApprovePO || false
}

const actions = {
	async setUser({commit}, response) {
		localStorage.setItem("token", response.token);
        await commit("setUser", response);
        await commit('initState');
        response.to !== undefined ? router.push("/" + response.to + '/dashboard') : router.push("/");
	},
	async logOut({commit}) {
        localStorage.clear();
		await commit('unSetUser');
	},
	async setLoading({commit}, status) {
		await commit("setLoading", status);
	},
	async setMenu({commit}, menu) {
		await commit('setMenu', menu);
	},
	async resetApp({commit}) {
		await commit("unSetApp");
	},
	async setApp({commit}, apps) {
		await commit('setApp', apps);
    },
    async setAppRole({commit}, roles) {
        await commit('setAppRole', roles);
    },
    async setModule({commit}, modules) {
        await commit('setModule', modules);
    },
    async updateUser({commit}, data) {
        await commit("updateUser", data);
    }
}

const mutations = {
    changeCurrentPage(state, payload){
        state.currentPage=payload;
    },
    initState(state) {
        state.menuDefault = menu;
        state.modules = [];
    },
    setLoading(state, status) {
        state.loading = status;
    },
    setUser(state, response) {
        state.token = response.token;
        state.user = response.profile;
        state.abilitiesApproveSO = response.profile.so_approver
        state.abilitiesApprovePO = response.profile.po_approver
    },
    updateUser(state, data) {
        state.user = data;
    },
    unSetUser(state) {
        state.loading = false;
        state.token = "";
        state.user = {};
        state.abilitiesApproveSO = false
        state.abilitiesApprovePO = false
        state.listOfMenu = [];
        state.application = false;
    },
    setMenu(state, menu) {
        state.listOfMenu = menu;
    },
    unSetApp(state) {
        state.application = false;
        state.loading = false;
    },
    setApp(state) {
        state.application = true;
    },
    setAppRole(state, roles) {
        state.applicationRole = roles;
    },
    setModule(state, modules) {
        state.modules = modules;
    }
};

export default {
	state,
	getters,
	actions,
	mutations
}
